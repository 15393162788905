import React from 'react';
import {Typography, Box} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

export default function HeaderCenter({children, ...props}) {
    const classes = useStyles();
    return (
        <Box className={classes.heroContent} {...props}>
            <Container maxWidth="md" className={classes.heroButtons}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        pegLIT
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        Automatically identify non-interfering nucleotide linkers between a pegRNA and 3' motif.
                    </Typography>
                    <Typography variant="subtitle1" align="center" paragraph>
                    <Link color="textSecondary" href="/about">
                        Learn more...
                    </Link>
                    </Typography>
                </Container>
                {children}
            </Container>
        </Box>
    );
}

import React from 'react';
import {
    useParams
  } from "react-router-dom";
import { Box, CircularProgress, LinearProgress, Snackbar, IconButton, TableFooter, Typography, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import ReactDataSheet from 'react-datasheet';

import {CSVLink} from 'react-csv';

import {CustomTableCell} from './RNAInputPage.js';

const REDIS_TERM_STATUSES = ["finished", "stopped", "failed"];
const REDIS_SCHED_STATUSES = ["queued", "deferred"]
const REDIS_RUN_STATUS = "started"

const useStyles = makeStyles((theme) => ({
  hint: {
    fontStyle: "italic",
  },
  noPad: {
    padding: 0,
  },
}));

const motifNames = {
    "CGCGGTTCTATCTAGTTACGCGTTAAACCAACTAGAA": <span>tevopreQ<sub>1</sub></span>,
    "GGGTCAGGAGCCCCCCCCCTGAACCCAGGATAACCCTCAAAGTCGGGGGGCAACCC": <span>mpknot</span>
  }

function CustomCell(props) {
  const classes = useStyles();
  let hint;
  let body = (<Typography noWrap variant="body1">{props.value}</Typography>);
  if (props.col === 4) {
      if (props.cell.status === REDIS_RUN_STATUS) {
        hint = props.cell.eta ? `${Math.round(props.cell.eta)} sec` : props.cell.status;
        body = (<LinearProgress
                  variant={props.cell.eta ? "determinate" : "indeterminate"}
                  value={props.cell.overall_progress} />
                );
      }
      else if (REDIS_SCHED_STATUSES.includes(props.cell.status)) {
        hint = props.cell.status;
        body = (<LinearProgress variant="indeterminate" />);
      }
      else if (props.cell.status === "finished") {
        hint = String(props.cell.linkers[0].length) + " bp";
        body = (<Typography noWrap variant="body1">{props.cell.linkers[0]}</Typography>);
      }
      else { // stopped or failed
        hint = props.cell.status;
        body = (<LinearProgress variant="determinate" value={0} />);
      }
  }
  else if (props.col === 5) {
      hint = ((props.value in motifNames) && (motifNames[props.value])) || " ";
  }
  else {
      hint = " ";
  }
  return (<Box {...props}>
      <Typography variant="caption" className={classes.hint} color="textSecondary">{hint}</Typography>
      {body}
      </Box>);
}

function ResultPage() {
    const classes = useStyles();
    let { jobId } = useParams();
    const [pegrnas, setPegrnas] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [eta_timeout, setEtaTimeout] = React.useState(null);
    // timeout warning
    const [eta_timeout_dismissed, setEtaTimeoutDismissed] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setEtaTimeoutDismissed(true);
      };
    React.useEffect(() => {
        fetch("/api/generate/input/" + jobId).then(response => response.json()).then(
            data => {
                setPegrnas(data.pegrnas);
            }
        );
    }, [jobId]);
    React.useEffect(() => {
        if (pegrnas) {
            const fetchProgress = () => {
                fetch("/api/generate/progress", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ jobids: pegrnas.map(pegrna => pegrna.jobid) })
                })
                .then(response => response.json())
                .then(
                    data => {
                        setStatus(data);
                        setEtaTimeout(pegrnas.some(pegrna => data[pegrna.jobid].timeout_warn));
                        if (pegrnas.every(pegrna => REDIS_TERM_STATUSES.includes(data[pegrna.jobid].status))) {
                            clearInterval(interval);
                        }
                    }
                );
            };
            fetchProgress();
            const interval = setInterval(fetchProgress, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [pegrnas]);
    if (pegrnas && status) {
        return (
            <div>
                <ReactDataSheet
                    data={pegrnas.map(pegrna => [pegrna.seq_spacer, pegrna.seq_scaffold, pegrna.seq_template, pegrna.seq_pbs, {
                        linker_pattern: pegrna.linker_pattern,
                        ...status[pegrna.jobid]
                    }, pegrna.seq_motif])}
                    overflow="clip"
                    valueRenderer={(cell, row, col) => {
                        if (col === 4) {
                            if (cell.status === "finished") {
                                return cell.linkers[0];
                            }
                            else {
                                return cell.linker_pattern;
                            }
                        }
                        else {
                            return cell;
                        }
                    }}
                    cellRenderer={CustomTableCell}
                    valueViewer={CustomCell}
                    dataEditor={CustomCell} // readonly
                    sheetRenderer={({children, ...props}) => (
                    <TableContainer component={Paper}>
                        <Table {...props}>
                        <TableHead>
                            <TableRow>
                            <TableCell>Spacer</TableCell>
                            <TableCell>Scaffold</TableCell>
                            <TableCell>Template</TableCell>
                            <TableCell>PBS</TableCell>
                            <TableCell>Linker</TableCell>
                            <TableCell>Motif</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {children}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colspan={6} className={classes.noPad}>
                                    <CSVLink data={
                                        pegrnas.map(pegrna => ({
                                            spacer: pegrna.seq_spacer,
                                            scaffold: pegrna.seq_scaffold,
                                            template: pegrna.seq_template,
                                            PBS: pegrna.seq_pbs,
                                            linker: status[pegrna.jobid].linkers ? status[pegrna.jobid].linkers[0] : "--------",
                                            motif: pegrna.seq_motif
                                        }))
                                    } filename={"linker_designs.csv"}>
                                        <Tooltip title="Export CSV">
                                            <IconButton>
                                                <GetAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </CSVLink>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                        </Table>
                    </TableContainer>
                    )}
                    rowRenderer={({children, ...props}) => (
                        <TableRow {...props}>
                            {children}
                        </TableRow>
                    )}
                />
                {pegrnas.some(peg => status[peg.jobid].expires_at) &&
                    <Typography variant="body2" color="textSecondary">
                        This page expires {status[pegrnas[0].jobid].expires_at} UTC.
                    </Typography>
                }
                {eta_timeout && 
                    <Snackbar
                        open={!eta_timeout_dismissed}
                        onClose={handleClose}
                        message="Your job(s) might timeout. Consider installing and running pegLIT on your own computer."
                        action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                        }
                    />
                }
            </div>
            );
    }
    else {
        return (
            <Box align="center"><CircularProgress /></Box>
        );
    }
}

export default ResultPage;

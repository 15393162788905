import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Obfuscate from './Obfuscate.js';

import { Typography } from '@material-ui/core';
import HeaderCenter from './HeaderCenter.js';
import HeaderGrid from './HeaderGrid.js';
import RNAInputPage from './RNAInputPage.js';
import ResultPage from './ResultPage.js';

import './react-datasheet.css';

const useStyles = makeStyles((theme) => ({
  quote: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[300],
    overflow: "auto",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  code: {
    whiteSpace: "nowrap",
    fontFamily: "Courier",
  },
}));

export default function App() {
  const classes = useStyles();
  const tabs = [{id: "about", title: "About"}, {id: "installation", title: "Installation"}, {id: "usage", title: "Usage"}];
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <RNAInputPage />
        </Route>
        <Route exact path="/result/:jobId">
          <HeaderCenter>
            <ResultPage />
          </HeaderCenter>
        </Route>
        <Route exact path="/about">
          <HeaderGrid thisTab="about" otherTabs={tabs}>
            <Typography variant="h4">About</Typography>
            <Typography paragraph>
              pegRNA Linker Identification Tool (pegLIT) automatically identifies non-interfering nucleotide
              linkers between a pegRNA and 3' motif. For more about the underlying science, please
              check out our publication:
            </Typography>
            <Typography paragraph className={classes.quote}>
              J. Nelson, P. Randolph, S. Shen, K. Everette, P. Chen, A. Anzalone, M. An, G. Newby, J. Chen,
              A. Hsu, and D. Liu. Engineered pegRNAs that improve prime editing efficiency. <i>Nature Biotechnology</i> (2021).
            </Typography>
            <Typography variant="h5">Contact</Typography>
            <Typography paragraph>
              We appreciate all questions, bug reports, feature requests, and other feedback about pegLIT. Please email <Obfuscate email="simonshen@fas.harvard.edu" element={Link} />.
            </Typography>
            </HeaderGrid>
          </Route>
          <Route exact path="/usage">
            <HeaderGrid thisTab="usage" otherTabs={tabs}>
            <Typography variant="h4">Usage</Typography>
            <Typography paragraph>
              The input to the algorithm consists of a pegRNA sequence and a 3' motif sequence. The output
              is the sequence of a nucleotide linker that is predicted to minimally interfere with the pegRNA
              or the 3' motif and thus is recommended for linking these components. Additional user-defined parameters
              further constrain the algorithm's search for valid linkers.
            </Typography>
            <Typography paragraph>
              For more documentation, please refer to the <Link href="https://github.com/sshen8/peglit">readme</Link>.
            </Typography>
            <Typography variant="h5">1. Design the pegRNA</Typography>
            <Typography paragraph>
              Based on your desired prime editing outcome, come up with sequences for the <b>spacer</b>, <b>scaffold</b>, <b>template</b>, and <b>PBS</b>.
              These represent four of the six inputs to the pegLIT web app, and they should be given as
              sequences from the actual pegRNA molecule in 5'-to-3' orientation. It's ok to use Ts and/or Us.
            </Typography>
            <Typography variant="h5">2. Pick epegRNA components</Typography>
            <Typography paragraph>
              pegLIT will identify promising linker sequences, but users can optionally specify a <b>linker pattern</b> that must be matched
              by the outputted sequences. The pattern should be written with IUPAC degenerate nucleotide symbols. For example, pegLIT takes
              NNNNNNNN as the default linker pattern input to identify an 8-nt linker.
            </Typography>
            <Typography paragraph>
              For the 3' <b>motif</b>, pegLIT defaults to the sequence of tevopreQ<sub>1</sub>. In the web app, this choice can be overridden
              by picking a different 3' motif sequence from the dropdown or by typing any custom sequence. As above, all input sequences
              here should correspond to the actual pegRNA molecule in 5'-to-3' orientation.
            </Typography>
            <Typography variant="h5">3. Repeat</Typography>
            <Typography paragraph>
              If you're working with multiple pegRNAs, you can submit them all in one batch to the pegLIT web app. Click the + button to add another row of inputs for each of your pegRNAs.
            </Typography>
          </HeaderGrid>
        </Route>
        <Route exact path="/installation">
          <HeaderGrid thisTab="installation" otherTabs={tabs}>
            <Typography variant="h4">Installation</Typography>
            <Typography paragraph>
              There are two main ways to access and use pegLIT.
              <ul>
                <li>The <b>web app</b> is probably the easiest and is sufficiently powerful for most use cases. No installation needed — you're already here.</li>
                <li>If your pegRNAs are unusually long (say &gt;150 nt), pegLIT might need more time than the 10-minute limit we've imposed for our server. In that case, you can install pegLIT onto your own computer via our <b>Python package</b>, which will let you run pegLIT as part of your own custom pipeline.</li>
              </ul>
            </Typography>
            <Typography variant="h5">Python package</Typography>
            <Typography paragraph>
              To install pegLIT from <Link href="https://bioconda.github.io/">Bioconda</Link> into your current conda environment:
            </Typography>
            <div className={classes.codeBlock}>
              <Typography className={classes.code}>
                conda install peglit
              </Typography>
            </div>
            <Typography variant="h6">Additional steps for Apple silicon</Typography>
            <Typography paragraph>
              If you're running a <Link href="https://support.apple.com/en-us/HT211814">Mac with Apple silicon</Link>, you need to do a few more things because some of pegLIT's dependencies aren't compatible with Apple silicon yet.
            </Typography>
            <Typography>
              <ol>
                <li>
                  Install <Link href="https://support.apple.com/en-us/HT211861">Rosetta</Link> if you haven't already:
                  <div className={classes.codeBlock}>
                    <Typography className={classes.code}>
                      softwareupdate --install-rosetta
                    </Typography>
                  </div>
                </li>
                <li>
                  Create and activate a conda environment that uses the Intel versions of packages:
                  <div className={classes.codeBlock}>
                    <Typography className={classes.code}>
                      CONDA_SUBDIR=osx-64 conda create -n peglit_env<br />
                      conda activate peglit_env
                    </Typography>
                  </div>
                </li>
                <li>
                  Now you can install pegLIT.
                </li>
              </ol>
            </Typography>
            </HeaderGrid>
          </Route>
      </Switch>
    </Router>
  );
}

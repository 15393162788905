import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { Button, Box, TableFooter, IconButton, Typography, InputBase, Backdrop, Snackbar, Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PublishIcon from '@material-ui/icons/Publish';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';

import ReactDataSheet from 'react-datasheet';

import HeaderCenter from './HeaderCenter.js';

const useStyles = makeStyles((theme) => ({
  hint: {
    fontStyle: "italic",
  },
  input: {
    padding: 0,
  },
  noPad: {
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableCell: {
    textAlign: "left",
    cursor: "cell",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    verticalAlign: "bottom",
  }
}));

const motifNames = {
    "CGCGGTTCTATCTAGTTACGCGTTAAACCAACTAGAA": {
      displayName: <span>tevopreQ<sub>1</sub></span>,
      searchNames: ["tevopreq1", "evopreq1-trimmed", "evopreq1 trimmed", "trimmed-evopreq1", "trimmed evopreq1"]
    },
    "GGGTCAGGAGCCCCCCCCCTGAACCCAGGATAACCCTCAAAGTCGGGGGGCAACCC": {
      displayName: <span>mpknot</span>,
      searchNames: ["mpknot",]
    }
  }

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function CustomTableCell({className, ...props}) {
  const classes = useStyles();
  return <TableCell {...props} className={clsx(classes.tableCell, className)} />
}

function CustomCell(props) {
  const classes = useStyles();
  let hint;
  if (props.col === 4) {
      hint = String(props.value.length) + " bp";
  }
  else if (props.col === 5) {
      hint = ((props.value in motifNames) && (motifNames[props.value]).displayName) || " ";
  }
  else {
      hint = " ";
  }
  return (<Box {...props}>
      <Typography variant="caption" className={classes.hint} color="textSecondary">{hint}</Typography>
      <Typography noWrap variant="body1">{props.value}</Typography>
      </Box>);
}

function CustomCellEdit({value, row, col, cell, onChange, onKeyDown, onCommit, onRevert, ...props}) {
  // const [highlightedVal, setHighlightedVal] = React.useState(value);
  const classes = useStyles();
  let hint;
  value = value === " " ? "" : value;
  let body = (<InputBase autoFocus className={classes.input} inputProps={{className: classes.input}} value={value} onChange={(event) => onChange(event.target.value)} onKeyDown={onKeyDown} />);
  if (col === 4) {
      hint = String(value.length) + " bp";
  }
  else if (col === 5) {
      hint = ((value in motifNames) && (motifNames[value].displayName)) || " ";
      body = (<Autocomplete
        freeSolo disableClearable fullWidth openOnFocus
        disablePortal // keeps popper in same DOM hierarchy to avoid issues with closing and cell toggling away from edit
        options={Object.keys(motifNames)}
        onChange={(event, val, reason) => reason === "select-option" && onCommit(val, event)}
        onInputChange={(event, val, reason) => reason === "input" && onChange(val)}
        inputValue={value}
        className={classes.input}
        renderInput={(params) => {
          const {InputLabelProps, InputProps, ...restParams} = params;
          const {inputProps, ...restRestParams} = restParams;
          const {className, ...restInputProps} = inputProps;
          return <InputBase {...InputProps} inputProps={{className: clsx(className, classes.input), ...restInputProps}} {...restRestParams} autoFocus onKeyDown={onKeyDown} />
        }}
        getOptionLabel={(option) => option || ""}
        renderOption={(option) => (
          <Box width={1}>
            <Typography variant="caption" className={classes.hint} color="textSecondary">{motifNames[option].displayName}</Typography>
            <Typography noWrap variant="body1">{option}</Typography>
          </Box>
        )}
        filterOptions={(options, state) => {
          if (state.inputValue) {
            const query = state.inputValue.toUpperCase();
            return options.sort(option => !(option.toUpperCase().includes(query) || motifNames[option].searchNames.some(haystack => haystack.toUpperCase().includes(query))));
          }
          else {
            return options;
          }
        }}
      />);
  }
  else {
      hint = " ";
  }
  return (<Box {...props}>
      <Typography variant="caption" className={classes.hint} color="textSecondary">{hint}</Typography>
      <Typography variant="body1">{body}</Typography>
    </Box>);
}

const defaultPeg = [
  " ",
  "GTTTTAGAGCTAGAAATAGCAAGTTAAAATAAGGCTAGTCCGTTATCAACTTGAAAAAGTGGCACCGAGTCGGTGC",
  " ",
  " ",
  "NNNNNNNN",
  "CGCGGTTCTATCTAGTTACGCGTTAAACCAACTAGAA"
]

export default function RNAInputPage() {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [batchSequences, setBatchSequences] = React.useState([defaultPeg])
  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onerror = () => setErrorMessage("Error reading file.");
    reader.onload = (event) => {
      // Parse file
      const rows = event.target.result.split(/\r?\n|\r|\n/g);
      const headerRow = rows[0].split(",");
      const dataRows = rows.slice(1).map(row => row.split(","));
      // Align columns to expected
      const headerNames = ["spacer", "scaffold", "template", "PBS", "linker_pattern", "motif"]
      const headerIdx = headerNames.map(name => headerRow.indexOf(name))
      // Sort data rows to expected column order
      const newPegs = dataRows.map(row => headerIdx.map(colIdx => colIdx >= 0 ? row[colIdx] : ""))
      if (batchSequences.length === 1 && batchSequences[0] === defaultPeg) {
        setBatchSequences(newPegs)
      }
      else {
        setBatchSequences([...batchSequences, ...newPegs])
      }
    };
    acceptedFiles.forEach(file => reader.readAsText(file));
  }, [batchSequences]);
  const {getRootProps, getInputProps, open, isDragActive} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})
  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setErrorMessage(null);
    };
  const handleSubmit = (event) => {
    event.preventDefault();
    window.gtag('event', 'peglit_submit', {
      'event_category': 'engagement',
      'value': batchSequences.length
    });
    fetch("/api/generate/start", {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      mode: "cors",
      body: JSON.stringify({sequences: batchSequences.map(seq => seq.map(comp => comp === " " ? "" : comp))})
    }).then(response => response.ok ? response.json() : Error()).then(data => window.location.href = "/result/" + data.jobids[data.jobids.length - 1]).catch(error => setErrorMessage("Server error"));
  }
  return (
    <Box {...getRootProps()}>
      <HeaderCenter>
        <form onSubmit={handleSubmit}>
          <input {...getInputProps()} />
            <ReactDataSheet
              data={batchSequences}
              overflow="clip"
              valueRenderer={(cell, row, col) => cell}
              cellRenderer={CustomTableCell}
              valueViewer={CustomCell}
              dataEditor={CustomCellEdit}
              onCellsChanged={changes => {
                const grid = batchSequences.map(row => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  grid[row][col] = value || " ";
                });
                setBatchSequences(grid);
              }}
              sheetRenderer={({children, ...props}) => (
                <TableContainer component={Paper}>
                  <Table {...props}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Spacer</TableCell>
                        <TableCell>Scaffold</TableCell>
                        <TableCell>Template</TableCell>
                        <TableCell>PBS</TableCell>
                        <TableCell>Linker Pattern</TableCell>
                        <TableCell>Motif</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {children}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colspan={6} className={classes.noPad}>
                          <Tooltip title="Add Row">
                            <IconButton onClick={() => setBatchSequences([...batchSequences, defaultPeg])}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Import CSV">
                            <IconButton onClick={open}>
                              <PublishIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}
            />
            <Box my={4} align="center">
              <Button variant="contained" color="primary" type="submit">
                  Start
              </Button>
            </Box>
        </form>
      </HeaderCenter>
      <Backdrop className={classes.backdrop} open={isDragActive}>
        <Typography>Drop your CSV file containing pegRNA inputs to be appended.</Typography>
      </Backdrop>
      <Snackbar
          open={errorMessage}
          onClose={handleClose}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      >
        <Alert onClose={handleClose} severity={"error"}>{errorMessage}</Alert>
      </Snackbar>
    </Box>
  );
}

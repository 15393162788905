import React, { useState } from 'react';

export default function Obfuscate(props) {
  const {
    element,
    email,
    ...others
  } = props;

  const [humanInteraction, setHumanInteraction] = useState(false);
  const linkProps = email;
  const Component = element;

  const generateLink = () => {
    return `mailto:${email}`;
  };

  const handleClick = () => {
    // If focused or hovered, this js will be skipped with preference for html
    if (humanInteraction === false) {
      window.location.href = generateLink({
        email,
      });
    }
  };

  const reverse = (content) =>
    typeof content !== 'undefined' &&
    content.split('').reverse().join('').replace('(', ')').replace(')', '(');

  const obfuscatedStyle = {
    unicodeBidi: 'bidi-override',
    direction:
      humanInteraction
        ? 'ltr'
        : 'rtl',
  };

  const renderedLink =
    humanInteraction
      ? linkProps
      : reverse(linkProps);

  const clickProps = {
          href:
            humanInteraction
              ? generateLink()
              : 'obfuscated',
          onClick: handleClick,
        };

  return (
    <Component
      onFocus={() => setHumanInteraction(true)}
      onMouseOver={() => setHumanInteraction(true)}
      onContextMenu={() => setHumanInteraction(true)}
      {...others}
      {...clickProps}
      style={obfuscatedStyle}
    >
      {renderedLink}
    </Component>
  );
};
